.footer {
    z-index: 1;
    font-size: $footer-font-size;
    background: $white;
    .footer-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0.5rem calc(var(--bs-gutter-x, 1rem) * 2);
        @include media-breakpoint-down(md) {
            justify-content: center;
        }
    }
    &.sticky{
        position: sticky;
        bottom: 0;
    }
    &.glass{
        background-color: rgba($white, .1);
        border-top: 1px solid $white;
        backdrop-filter: blur(10px);
    }
}
.slideouticons {
    position: fixed;
    bottom: 3.5rem;
    left: auto;
    right: 2rem;
    label {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 1.5rem;
        width: 3rem;
        height: 3rem;
        text-align: center;
        color: var(--bs-white);
        background: var(--bs-primary);
        border-radius: 100px;
        outline: none;
        z-index: 100000;
        transition: all 0.3s;
    }
    .iconswrapper {
        background: var(--#{$variable-prefix}primary-tint-90);
        position: absolute;
        margin: 0;
        bottom: 1.5rem;
        left: 0;
        right: 0;
        text-align: center;
        clip-path: inset(100% 0 0 0);
        border-radius: $border-radius;
        transition: all 1s ease 0s;
        padding: .5rem 0 1.5rem;
        ul {
            position: relative;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin-bottom: 10px;
                position: relative;
                width: 45px;
                height: 45px;
                line-height: 45px;
                margin: 0 auto;
                a {
                    color: $headings-color;
                }
            }
        }
    }
    &:hover {
        .iconswrapper {
            visibility: visible;
            clip-path: inset(0 0 0 0);
        }
    }
}