// sidebar-mini class

.sidebar-default {
    &:not(.left-bordered){
        &.sidebar-soft{
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        &:not(.disabled) {
                            &.active,&[aria-expanded=true]{
                                background-color: rgba(var(--#{$variable-prefix}primary-rgb),.1);
                                box-shadow: unset;
                                color: var(--#{$variable-prefix}primary);
                            }
                            &:hover:not(.active):not([aria-expanded=true]) {
                                background-color: unset;
                                color: var(--#{$variable-prefix}primary);
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
            &.sidebar-color{
                .navbar-nav {
                    .nav-item {
                        .nav-link {
                            &:not(.disabled) {
                                &.active,&[aria-expanded=true]{
                                    background-color: rgba(var(--#{$variable-prefix}white-rgb), .1);
                                    box-shadow: unset;
                                    color: var(--#{$variable-prefix}white);
                                }
                                &:hover:not(.active):not([aria-expanded=true]) {
                                    background-color: rgba(var(--#{$variable-prefix}white-rgb),.2);;
                                    color: var(--#{$variable-prefix}white);
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
