// nav-rounded

.sidebar{
    &.navs-rounded{
        .sidebar-body{
            .nav-item {
                &.static-item {
                    padding: 0;
                    .nav-link{
                        padding: .5rem 1rem;
                    }
                }
                padding: 0;
                .nav-link{
                    padding: .5rem 1.25rem;
                    border-top-right-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;
                }
                .sub-nav {
                    .nav-item {
                        .nav-link {
                            padding-left: 1.8rem;
                        }
                    }
                }
            }
        }
    }
}
