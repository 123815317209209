.main-content{
    .content-inner {
        min-height: calc(100vh - 6.6rem);
    }
}
// .main-content{
//     .iq-banner:not(.hide) + .content-inner {
//         min-height: calc(100vh - 17.313rem);
//     }
// }
.main-content{
    .iq-banner:not(.hide) + .content-inner {
        min-height: calc(100vh - 12.313rem);
    }
}
.main-content{
    .content-inner.navbar-sticky-space {
        padding-top: 4.875em;
    }
}
.sidebar {
    &+.main-content {
        margin-left: var(--sidebar-width);
        transition: var(--sidebar-transition);
        transition-duration: var(--sidebar-transition-duration);
        transition-timing-function: var(--sidebar-transition-function-ease-in-out);
    }
}
@media (min-width: 1200PX) {
    .sidebar {
        &+.main-content {
            .nav {
                .navbar-brand{
                    display: none;
                }
            }
        }
    }
}
